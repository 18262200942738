import logo from './pics/common/logo_gold_1000px.png';

import './App.css';

function getThisYear() {
    const today = new Date();
    const year = today.getFullYear();

    return year;
};

function Footer() {
  return (
    <footer className="App-footer">

        <div className="copany-name">
          <div className="logo">
            <a href="/">
                <img src={logo} className="App-logo" alt="logo" />
            </a>
          </div>
          <div className="copany-title">
            <div className="font04em">
              C＆Rグループ
            </div>
            <div className="font09em">
              雨宿り- AMAYADORI
            </div>
          </div>
          <div className='footer-link'>
           <a href="./#home"> ホーム</a>
           <a href="./#about-c&r"> C＆Rグループについて</a>
           <a href="./#about-our-job">ご相談内容</a>
           <a href="./#prices"> 費用</a>
           <a href="./#representative"> 代表</a>
           <a href="./#contact-us"> お問い合わせ</a>
            </div>
        </div>
        <div className="copyright">
            ©C&Rグループ 雨宿り-AMAYADORI {getThisYear()}
        </div>
    </footer>
  );
}

export default Footer;