import React, { useState } from 'react';
//import firebase from 'firebase/app';
//import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { collection, addDoc } from"firebase/compat/firestore";


import './App.css';


//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button'

//const firebase = require("firebase");
//require("firebase/functions");

import ContactFunction from './ContactFunction';

let formStateData = {
  name: '',
  email: '',
  tel: '',
  details: '',
};

function ContactForm() {
  
  const [formData, setFormData] = useState(formStateData);

  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  };

  function clearUseState() {
    const clearState = {
      ...formData,
      name: '',
      email: '',
      tel: '',
      details: '',
    }
    setFormData(clearState);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    try{
      ContactFunction(formData);
      alert("お問い合わせが完了しました。\nもし2・3日以内にご連絡がない場合は再度お問い合わせいただくか、\"info@group-amayadori.com\"にご連絡ください。");
      clearUseState();
    }
    catch(e)
    {
        console.log("Error: "+e);
    }
  };
 

return (
    <>
      <div id="contact">
      <h2>お問い合わせ</h2>
      <form onSubmit={handleSubmit}>
          <div>
          <label>お名前</label><br/>
            <input  
              type="text" 
              name="name" 
              className="input"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
        <div>
        <label>メールアドレス</label><br/>
          <input 
            type="email" 
            name="email" 
            className="input"
            value={formData.email}
            onChange={handleInputChange}
            pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
          />
          </div>
        <div>
          <label>電話番号</label><br/>
          <input  
            type="tel" 
            name="tel" 
            className="input"
            value={formData.tel}
            onChange={handleInputChange}
            pattern="\d{2,4}-?\d{2,4}-?\d{3,4}"
            required
          />
          </div>
        <div>
        <label>ご相談内容</label><br/>
          <textarea   
            type="textarea" 
            name="details" 
            value={formData.details}
            onChange={handleInputChange}
            required
          />
          </div>
        <input className="button" type="submit" value="送信" />
      </form>
      </div>
      </>
  );
}
export default ContactForm;
