import logo from './pics/common/logo_gold_1000px.png';
import photo_representative from './pics/home/Toshinori_Tanaka_1000px.jpg';
import webpage_header from './pics/home/webpage_header.jpg';

import './App.css';

import ContactForm from "./ContactForm";

function Home() {
  return (
    <>
      <img src={webpage_header} className="home-headerImage" alt="home-headerImage" />
      <div className='body'>
        <h2 id="about-c&r">C＆Rグループについて</h2>
        <div>
          C＆Rグループは、「福祉・教育・保育・就職・法律・出版活動分野」などに関する東京都内や神奈川県内、山梨県内で相談及び支援を業とする専門家の集まりです。 相談と接遇 (Call＆Response)、援助と救済 (Care＆Rescue)、仕事と人財(Carrer＆Resource)など、その場・その時に応じた適切なC＆R サービスを提供していきます。<br/>
          <br/>
          山梨県南都留郡富士河口湖町を中心に活動する「雨宿り」は主に富士河口湖町や富士吉田市を中心として、山梨県内のお子様の発達やコミュニケーション障害、引き篭もり、学習困難、非行等に関する相談及び支援に尽力しています。また、家庭内の不和やDV（ドメステイックバイオレンス）、職場におけるパワハラ（パワーハラスメント）等の問題の相談・支援にも取り組んでいます。加えて、必要に応じてPCのズームを活用した就職の個別面接やグループ面接、再就職の為のアドバイスや面接等についても事前準備の段階からサポートをさせていただいています。 <br/>
          <br/>
          なお、「雨宿り」は代表の田中利則が相談支援の担当を致しますが、相談の内容や質に応じて東京都内や神奈川県内で活動している各領域の専門家（例えば、医師、弁護士、労働組合等）が担当させていただくことがありますので、その点に関してはご了解ください。<br/>
          <br/>
          C＆Rグループは来談者の抱える問題に応じて、適切なアドバイスと各分野の専門家が問題解決のためにチームをつくり、終結するまでお手伝いを致しますので、気軽に声をかけてください。
        </div>
        <h2 id="about-our-job">ご相談内容</h2>
          <div className='inline'>
            <div className='inline-fit-content'>
            <b>ご家庭の問題：</b>
              <ul>
                <li>発達障害</li>
                <li>コミュニケーション障害</li>
                <li>引き篭もり</li>
                <li>非行　など</li>
              </ul>
            </div>
            <div className='inline-fit-content'>
            <b>ご家庭の問題：</b>
              <ul>
                <li>夫婦間・親子間のコミュニケーション</li>
                <li>DV（ドメステイックバイオレンス）</li>
                <li>労働問題（不当解雇、賃金未払い など）</li>
                <li>再就職の相談（面接指導を含む）など</li>
              </ul>
            </div>
          </div>
          <h2 id="prices">費用</h2>
          <div>
            基本的には初回は<u>１時間500円</u>とさせていただきます。継続して相談を行う場合はケースによって費用が異なりますので、契約する際に改めて費用の提示をさせていただきます。
          </div>
          <div id='representative'>
            <div className='inline'>
              <img src={photo_representative} className="home-representative" alt="home-representative-Image" />
              <div className='inline-fit-content'>
                <h2 className='no-margin'>代表</h2>
                <div className='name-pronounce'>TANAKA TOSHINORI</div>
                <div className='name-chinese'>田中 利則</div>
                <h3 className='no-margin'>学歴</h3>
                <div className=''>
                  <ul>
                    <li>法政大学法学部法律学科卒</li>
                    <li>日本社会事業大学大学院 <br/>
                        社会福祉学研究科 博士前期課程 社会福祉学専攻修了</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <h3>職歴</h3>
              <li>聖ヨハネ会 富士聖ヨハネ学園児童部・成人部 科長</li>
              <li>武蔵野短期大学 保育学科 准教授</li>
              <li>武蔵野学院大学 国際コミュニケーション学部 准教授</li>
              <li>ソニー学園 湘北短期大学 保育学科 教授</li>
              <li>フジ虎ノ門こどもセンター 顧問</li>
            </div>
            <div>
              <h3>専門領域</h3>
              <div>ソーシャルワーク・ファミリーソーシャルワーク・障害児および障害者福祉・社会福祉教育等</div>
            </div>
          </div>
          <div id="contact-us">
              <ContactForm/>
            </div>
      </div>
    </>
  );
}

export default Home;
