import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';

async function ContactFunction(formData) {

    console.log("ContactFunction start");

    let formStateData = {
        name: '',
        email: '',
        tel: '',
        details: '',
    };  
   
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FB_MAIL_APIKEY,
        authDomain: process.env.REACT_APP_FB_MAIL_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FB_MAIL_PROJECTID,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        storageBucket: process.env.REACT_APP_FB_MAIL_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FB_MAIL_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FB_MAIL_APPID,
        measurementId: process.env.REACT_APP_FB_MAIL_MEASUREMENTID
    };

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const docRef = await addDoc(collection(db, "mail"), {
        to: process.env.REACT_APP_EMAIL_ADDRESS,
        message: {
            "subject": "[ "+formData.name+" ] ウェブページ問い合わせ" || null,
            "text": "以下のお問い合わせがありました。" || null,
            "html": "以下のお問い合わせがありました。<br><br><b>名前</b>: "+formData.name+"<br><b>Email</b>: "+formData.email+"<br><b>電話番号</b>: "+formData.tel+"<br><b>相談内容</b>: <br>"+formData.details+"<br>" || null,
        }
      });

      console.log("Completed : Mail Sending");
}
export default ContactFunction;
