import logo from './pics/common/logo_gold_1000px.png';
import photo_toshinori from './pics/home/Toshinori_Tanaka_1000px.jpg';
import webpage_header from './pics/home/webpage_header.jpg';

import './App.css';

function Header() {
  return (
    <header id="home" className="App-header">
      <div className="copany-name">
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap" rel="stylesheet"/>
        <div className="logo">
          <a href="/">
            <img src={logo} className="App-logo" alt="logo" />
          </a>
        </div>
        <div className="copany-title">
          <div className="font06em">
            C＆Rグループ
          </div>
          <div className="font11em">
            雨宿り- AMAYADORI
          </div>
        </div>
        <div className="header-contact">
          <a href="/#contact-us">
            <div className="contact-buton">
              お問い合わせ
            </div>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
